<template>
<!-- 设计与施工详情页 -->
    <div id="buildDetail">
        <!-- 商品分类 -->
        <Classification :types="1" :navData="$store.state.navData" :sidebarData="$store.state.sidebarData" ></Classification>
        <img class="buildDetail_banner" src="../../assets/img/build/build_banner.png" alt="">
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/build/index'}">设计与施工技术</el-breadcrumb-item>
                <el-breadcrumb-item>{{this.articleDetail.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="buildDetail_main">
            <p class="buildDetail_main_title">{{this.articleDetail.title}}</p>
            <img class="buildDetail_main_img" :src="this.articleDetail.coverUrl" alt="">
            <div v-html="this.articleDetail.content"></div>
        </div>
    </div>
</template>

<script>
import Classification from '@/components/classification.vue'
export default {
    name: 'buildDetail',
    components:{
        Classification
    },

    data() {
        return {
            // 文章id
            id:'',
            // 文章详情
            articleDetail:{},
        };
    },

    mounted() {
        // 获取文章id
        let url = this.$route.query;
        this.id = url.id;
        this.buildDetailInit();
    },

    methods: {
        // 查询文章详情
        buildDetailInit() {
            this.api.findArticleById({ id:this.id }).then(res => {
                this.articleDetail = res.data
            })
        }
    },
};
</script>

<style lang="scss" scoped>
#buildDetail{
    .buildDetail_banner{
        width: 100%;
        height: 300px;
    }
    .crumbs{
        width: 1200px;
        margin: 0 auto;
        margin-top: 22px;
        margin-bottom: 22px;
    }
    .buildDetail_main{
        margin: 0 auto;
        width: 1200px;
        margin-bottom: 66px;
        background: white;
        overflow: hidden;
        .buildDetail_main_title{
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: center;
            margin-top: 60px;
            margin-bottom: 52px;
        }
        .buildDetail_main_img{
            width: auto;
            height: auto;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;
        }
        div{
            margin: 0 auto;
            width: 1138px;
            padding-top: 31px;
            padding-bottom: 31px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-indent: 2em;
            line-height: 24px;
            ::v-deep p{
                img{
                    width: auto;
                    height: auto;
                    margin: 0 auto;
                }
            }
        }
    }
}
</style>